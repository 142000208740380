<template>
  <!-- 就诊评价 -->
  <div class="pingjia">
    <div class="pingjia1">
      <h3>就诊已结束！</h3>
      <div>您的评价会让我们做的更好</div>
      <van-rate :size="25" color="#ffd21e" v-model="score" @change="setScore" />
    </div>
    <textarea class="help1" v-model="comment" rows="10" type="textarea" />
    <van-button @click="tijiao">提交评价</van-button>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      score: 5,
      comment: "", // 评价内容
      patientId: "", //患者id
      patientName: "", //患者姓名
      deptName: "", // 科室名称
      regDate: "", //看病日期 //2020-07-06
      doctorId: "", //医生id
      info: {},
    };
  },
  methods: {
    setScore(e) {
      this.score = e;
    },
    getCommentInfo() {
      let postData = {
        deptName: this.info.dept_name, // 科室名称
        patientId: this.patientId, //患者id
        regDate: this.info.time.split("_")[0], //  看病日期
        doctorId: this.info.doctor_code, //医生id
      };
      this.$http
        .get("/api/comment/info", postData)
        .then((res) => {
          this.comment = res.data.comment;
          this.score = res.data.score;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tijiao() {
      if (!this.comment) {
        Toast("请对我们的服务做出评价");
        return;
      }
      let postData1 = {
        comment: this.comment,
        score: this.score,
        deptName: this.info.dept_name, // 科室名称
        patientId: this.patientId, //患者id
        regDate: this.info.time.split("_")[0], //  看病日期
        patientName: this.patientName,
        userid: sessionStorage.getItem("userid"), //用户id
      };

      console.log(postData1, "postData1");
      this.$http
        .post("/api/comment/add", postData1)
        .then((res) => {
          if (res.status == 200) {
            Toast({
              message: "评价成功",
              duration: 500,
              onClose: () => {
                this.$router.push({
                  name: "appointPayments",
                });
              },
            });
          }
        })
        .catch((err) => {
          Toast({ message: err.response.data });
          console.log(err);
        });
    },
  },
  created() {
    this.info = JSON.parse(window.sessionStorage.getItem("recordsList"));
    this.patientId = this.$route.query.id;
    this.patientName = this.$route.query.name;
    this.getCommentInfo();
  },
};
</script>
<style scoped>
.body {
  background-color: #f8f9f9;
}
.pingjia {
  width: 100%;
  height: 100%;
}
.pingjia1 {
  width: 9rem;
  height: 150px;
  margin: auto;
  font-size: 16px;
  line-height: 45px;
}
.help1 {
  width: 9rem;
  height: 150px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: auto;
  resize: none;
  text-align: left;
  font-size: 16px;
}
.van-button {
  width: 9rem;
  margin: auto;
  background-color: #00d48b;
  font-size: 18px;
  margin-top: 30px;
  color: #ffffff;
  border-radius: 10px;
}
</style>
